var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[(
      _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.MastersPages) ||
      _vm.loginUserRole == 'Admin'
    )?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/departmentView"}},[_c('project-card',{attrs:{"icon":"UsersIcon","statisticTitle":"Subscribers Gained","type":"area","titleName":"DEPARTMENT"}})],1)],1),_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/designationView"}},[_c('project-card',{attrs:{"icon":"PenToolIcon","statisticTitle":"Orders Received","type":"area","titleName":"DESIGNATION"}})],1)],1),_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/shootLocationView"}},[_c('project-card',{attrs:{"icon":"UsersIcon","statisticTitle":"Orders Received","type":"area","titleName":"SET NAME"}})],1)],1),(_vm.role_name == 'Admin' || _vm.permissionPageAccessArry.includes('40'))?_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/projects"}},[_c('project-card',{attrs:{"icon":"PlusCircleIcon","statisticTitle":"Orders Received","type":"area","titleName":"ADD PROJECT"}})],1)],1):_vm._e(),_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/categoryView"}},[_c('project-card',{attrs:{"icon":"GitMergeIcon","statisticTitle":"Orders Received","type":"area","titleName":"CATEGORY"}})],1)],1),_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/employmentView"}},[_c('project-card',{attrs:{"icon":"UsersIcon","statisticTitle":"Orders Received","type":"area","titleName":"EMPLOYMENT"}})],1)],1),(
        _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.MastersPages) ||
        _vm.loginUserRole == 'Admin'
      )?_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/countryView"}},[_c('project-card',{attrs:{"icon":"ShoppingBagIcon","statistic":"97.5K","statisticTitle":"Orders Received","type":"area","titleName":"COUNTRY"}})],1)],1):_vm._e(),(
        _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.MastersPages) ||
        _vm.loginUserRole == 'Admin'
      )?_c('div',{staticClass:"vx-col w-full lg:w-1/3 mb-5"},[_c('router-link',{attrs:{"to":"/currency"}},[_c('project-card',{attrs:{"icon":"ActivityIcon","statisticTitle":"Orders Received","type":"area","titleName":"CURRENCY"}})],1)],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"vx-row"}),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"filter-nav"},[_c('div',{staticClass:"flex flex-wrap justify-between items-center"},[_c('h4',{staticClass:"mb-0 font-bold ml-3"},[_vm._v("PROJECTS")]),_c('div',{staticClass:"flex flex-wrap justify-end items-center"},[_c('div',{staticClass:"flex flex-wrap items-center justify-between ag-grid-table-actions-right"},[_c('vs-input',{staticClass:"mb-4 md:mb-0 mr-4",attrs:{"placeholder":"Search..."},on:{"keyup":_vm.searchFilter},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}),_c('div',{staticClass:"flex flex-wrap items-center justify-between ag-grid-table-actions-right"},[(this.archive != 'archive')?_c('vx-tooltip',{attrs:{"text":"Export Excel"}},[_c('a',{attrs:{"href":_vm.excelurl +
                      "/v2/project/excel/download?organization_id=" + _vm.organization_id + "&token=" + _vm.temp_token + "&search=" + _vm.searchQuery,"target":"_blank"}},[_c('vs-button',{staticClass:"px-4"},[_c('span',{staticClass:"d-inline-flex pr-5 text-white"},[_vm._v("Export")]),_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/icons/export-excel.svg"),"height":"20","width":"20"}})])],1)]):_vm._e(),(this.archive == 'archive')?_c('vx-tooltip',{attrs:{"text":"Export Archive Excel"}},[_c('a',{attrs:{"href":_vm.excelurl +
                      "/v2/project/excel/download?organization_id=" + _vm.organization_id + "&token=" + _vm.temp_token + "&archive=4&search=" + _vm.searchQuery,"target":"_blank"}},[_c('vs-button',{staticClass:"px-4"},[_c('span',{staticClass:"d-inline-flex pr-5 text-white"},[_vm._v("Export")]),_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/icons/export-excel.svg"),"height":"20","width":"20"}})])],1)]):_vm._e()],1)],1)])])])])]),_c('vs-tabs',[_c('vs-tab',{attrs:{"label":"Project List","icon-pack":"feather","icon":"icon-box"},on:{"click":_vm.archiveList}},[_c('div',{staticClass:"tab-text"},[_c('project-list',{attrs:{"items":[1, 2, 3, 4, 5, 6, 7, 8, 9],"gridView":_vm.gridView,"listView":_vm.listView}})],1)]),(
        _vm.permissionPageAccessArry.includes(
          _vm.PermissionVariables.projectArchive
        ) || _vm.loginUserRole == 'Admin'
      )?_c('vs-tab',{attrs:{"label":"Archive List","icon-pack":"feather","icon":"icon-box"},on:{"click":_vm.archiveList}},[_c('div',[_c('project-list',{attrs:{"items":[1, 2, 3, 4, 5, 6, 7, 8, 9],"gridView":_vm.gridView,"listView":_vm.listView,"listType":"archive"}})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }