<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- v-if="role_name == 'Admin' || role_name == 'Admin Manager'" -->
    <div
      class="vx-row"
      v-if="
        permissionPageAccessArry.includes(PermissionVariables.MastersPages) ||
        loginUserRole == 'Admin'
      "
    >
      <!-- CARD : PROJECT -->
      <!-- <div class="vx-col w-full lg:w-1/4 mb-5">
        <router-link to="/userViews">
          <project-card
            icon="ShoppingBagIcon"
            statistic="92.6k"
            statisticTitle="Subscribers Gained"
            type="area"
            titleName="USERS"
          ></project-card>
        </router-link>
      </div>-->

      <!-- CARD 1: COMPANY -->
      <!-- v-if="loginUserRole == 'Admin'" -->
      <!-- <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/companyView">
          <project-card
            icon="BriefcaseIcon"
            statisticTitle="Subscribers Gained"
            type="area"
            titleName="COMPANY"
          ></project-card>
        </router-link>
      </div> -->

      <!-- CARD 1: DEPARTMENT -->
      <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/departmentView">
          <project-card
            icon="UsersIcon"
            statisticTitle="Subscribers Gained"
            type="area"
            titleName="DEPARTMENT"
          ></project-card>
        </router-link>
      </div>
      <!-- CARD 3: DESIGNATION -->
      <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/designationView">
          <project-card
            icon="PenToolIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="DESIGNATION"
          ></project-card>
        </router-link>
      </div>
      <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/shootLocationView">
          <project-card
            icon="UsersIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="SET NAME"
          ></project-card>
        </router-link>
      </div>
      <div
        v-if="role_name == 'Admin' || permissionPageAccessArry.includes('40')"
        class="vx-col w-full lg:w-1/3 mb-5"
      >
        <router-link to="/projects">
          <project-card
            icon="PlusCircleIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="ADD PROJECT"
          ></project-card>
        </router-link>
      </div>
      <!-- CARD 2: CATEGORY -->
      <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/categoryView">
          <project-card
            icon="GitMergeIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="CATEGORY"
          ></project-card>
        </router-link>
      </div>

      <!-- CARD 4: Shoot Location -->

      <!-- CARD 5: employment -->
      <div class="vx-col w-full lg:w-1/3 mb-5">
        <router-link to="/employmentView">
          <project-card
            icon="UsersIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="EMPLOYMENT"
          ></project-card>
        </router-link>
      </div>
      <!-- CARD 7: COUNTRY -->
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.MastersPages) ||
          loginUserRole == 'Admin'
        "
        class="vx-col w-full lg:w-1/3 mb-5"
      >
        <router-link to="/countryView">
          <project-card
            icon="ShoppingBagIcon"
            statistic="97.5K"
            statisticTitle="Orders Received"
            type="area"
            titleName="COUNTRY"
          ></project-card>
        </router-link>
      </div>
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.MastersPages) ||
          loginUserRole == 'Admin'
        "
        class="vx-col w-full lg:w-1/3 mb-5"
      >
        <router-link to="/currency">
          <project-card
            icon="ActivityIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="CURRENCY"
          ></project-card>
        </router-link>
      </div>
    </div>
    <!-- CARD 6: PROJECT -->
    <!-- v-if="role_name != 'Admin Manager'" -->
    <div class="vx-row">
      <!-- <div class="vx-col w-full lg:w-1/3 mb-5" v-if="role_name != 'Admin Manager'">
        <router-link to="/vendorView">
          <project-card
            icon="PlusCircleIcon"
            statisticTitle="Orders Received"
            type="area"
            titleName="ADD VENDOR CONTRACTS"
          ></project-card>
        </router-link>
      </div>-->

      <!-- CARD 5: USER ROLE -->
      <!-- <div class="vx-col w-full lg:w-1/4 mb-5">
        <router-link to="/userRoleView">
          <project-card
            icon="ShoppingBagIcon"
            statistic="97.5K"
            statisticTitle="Orders Received"
            type="area"
            titleName="USER ROLE"
          ></project-card>
        </router-link>
      </div>-->

      <!-- CARD 8: CURRENCY -->
    </div>

    <div class="vx-row">
      <!-- TOP FILTER NAVBAR-->
      <div class="vx-col w-full">
        <div class="filter-nav">
          <div class="flex flex-wrap justify-between items-center">
            <h4 class="mb-0 font-bold ml-3">PROJECTS</h4>

            <div class="flex flex-wrap justify-end items-center">
              <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
              <div
                class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              >
                <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchField"
                  v-on:keyup="searchFilter"
                  placeholder="Search..."
                />
                <div
                  class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                >
                  <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
                  </div>-->
                  <vx-tooltip
                    text="Export Excel"
                    v-if="this.archive != 'archive'"
                  >
                    <a
                      :href="
                        excelurl +
                        `/v2/project/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                      "
                      target="_blank"
                    >
                      <vs-button class="px-4">
                        <span class="d-inline-flex pr-5 text-white"
                          >Export</span
                        >
                        <img
                          src="@/assets/images/icons/export-excel.svg"
                          class="d-inline-flex"
                          height="20"
                          width="20"
                        />
                      </vs-button>
                    </a>
                  </vx-tooltip>

                  <vx-tooltip
                    text="Export Archive Excel"
                    v-if="this.archive == 'archive'"
                  >
                    <a
                      :href="
                        excelurl +
                        `/v2/project/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=4&search=${searchQuery}`
                      "
                      target="_blank"
                    >
                      <vs-button class="px-4">
                        <span class="d-inline-flex pr-5 text-white"
                          >Export</span
                        >

                        <img
                          src="@/assets/images/icons/export-excel.svg"
                          class="d-inline-flex"
                          height="20"
                          width="20"
                        />
                      </vs-button>
                    </a>
                  </vx-tooltip>
                </div>
                <!-- <ul class="list-inline">
                  <li
                    v-on:click="viewType('gridView')"
                    class="list-inline-item font-semibold cursor-pointer"
                  >
                    <feather-icon icon="GridIcon" svgClasses="h-7 w-7" />
                  </li>
                  <li
                    v-on:click="viewType('listView')"
                    class="list-inline-item font-semibold cursor-pointer"
                  >
                    <feather-icon icon="ListIcon" svgClasses="h-7 w-7" />
                  </li>
                </ul>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-tabs>
      <vs-tab
        label="Project List"
        icon-pack="feather"
        icon="icon-box"
        @click="archiveList"
      >
        <div class="tab-text">
          <project-list
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :gridView="gridView"
            :listView="listView"
          ></project-list>
        </div>
      </vs-tab>
      <vs-tab
        v-if="
          permissionPageAccessArry.includes(
            PermissionVariables.projectArchive
          ) || loginUserRole == 'Admin'
        "
        label="Archive List"
        icon-pack="feather"
        icon="icon-box"
        @click="archiveList"
      >
        <div>
          <project-list
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :gridView="gridView"
            :listView="listView"
            listType="archive"
          ></project-list>
        </div>
      </vs-tab>
    </vs-tabs>

    <!-- <div>
      <project-list :items="[1,2,3,4,5,6,7,8,9]" :gridView="gridView" :listView="listView"></project-list>
    </div>-->
    <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
  </div>
</template>

<script>
import ProjectCard from "@/components/project-cards/projectCard.vue";
import projectList from "@/components/movie-cards/projectList.vue";
import { BASEURL } from "@/config/index.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL(),
      archive: "",
      gridView: true,
      listView: false,
      searchField: "",
      searchQuery: "",
      isActive: false,
      role_name: "",
      analyticsData: [],
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      users: [
        {
          orderNo: 879985,
          status: "Moving",
          statusColor: "success",
          operator: "Cinar Knowles",
          operatorImg: "avatar-s-2.png",
          usersLiked: [
            { name: "Vennie Mostowy", img: "avatar-s-5.png" },
            { name: "Elicia Rieske", img: "avatar-s-7.png" },
            { name: "Julee Rossignol", img: "avatar-s-10.png" },
            { name: "Darcey Nooner", img: "avatar-s-8.png" },
          ],
          location: "Anniston, Alabama",
          distance: "130 km",
          distPercent: 80,
          startDate: "14:58 26/07/2018",
          estDelDate: "28/07/2018",
        },
        {
          orderNo: 156897,
          status: "Pending",
          statusColor: "warning",
          operator: "Britany Ryder",
          operatorImg: "avatar-s-4.png",
          usersLiked: [
            { name: "Trina Lynes", img: "avatar-s-1.png" },
            { name: "Lilian Nenez", img: "avatar-s-2.png" },
            { name: "Alberto Glotzbach", img: "avatar-s-3.png" },
          ],
          location: "Cordova, Alaska",
          distance: "234 km",
          distPercent: 60,
          startDate: "14:58 26/07/2018",
          estDelDate: "28/07/2018",
        },
        {
          orderNo: 568975,
          status: "Moving",
          statusColor: "success",
          operator: "Kishan Ashton",
          operatorImg: "avatar-s-1.png",
          usersLiked: [
            { name: "Lai Lewandowski", img: "avatar-s-6.png" },
            { name: "Elicia Rieske", img: "avatar-s-7.png" },
            { name: "Darcey Nooner", img: "avatar-s-8.png" },
            { name: "Julee Rossignol", img: "avatar-s-10.png" },
            { name: "Jeffrey Gerondale", img: "avatar-s-9.png" },
          ],
          location: "Florence, Alabama",
          distance: "168 km",
          distPercent: 70,
          startDate: "14:58 26/07/2018",
          estDelDate: "28/07/2018",
        },
        {
          orderNo: 245689,
          status: "Canceled",
          statusColor: "danger",
          operator: "Anabella Elliott",
          operatorImg: "avatar-s-6.png",
          usersLiked: [
            { name: "Vennie Mostowy", img: "avatar-s-5.png" },
            { name: "Elicia Rieske", img: "avatar-s-7.png" },
          ],
          location: "Clifton, Arizona",
          distance: "125 km",
          distPercent: 95,
          startDate: "14:58 26/07/2018",
          estDelDate: "28/07/2018",
        },
      ],
      organization_id: "",
    };
  },
  beforeMount: function () {
    this.organization_id = localStorage.getItem("OrganizationID");
    this.getUserRolePermissions();
  },
  methods: {
    archiveList() {
      console.log("fdsfsf");
      this.archive = this.archive != "" ? "" : "archive";
    },
    getUserRolePermissions: function () {
      this.role_name = window.localStorage.getItem("UserRole");
    },
    myFilter: function () {
      this.isActive = !this.isActive;
    },

    searchFilter: function () {
      eventBus.$emit("filterProject", this.searchField);
    },

    viewType: function (viewOption) {
      console.log("viewOption :", viewOption);
      if (viewOption == "gridView") {
        this.gridView = true;
        this.listView = false;
      } else if (viewOption == "listView") {
        this.listView = true;
        this.gridView = false;
      }
    },
  },
  components: {
    ProjectCard,
    projectList,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
